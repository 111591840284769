<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" md="8" class="pa-0">
      <v-card
        class="mx-auto pa-8"
        max-width="100%"
        :loading="isLoading"
      >
        <CardToolbar
          :icon="REPORTS_ICON"
          button-mode
          :back-button-route="REPORTS_ROUTE"
          back-button-tooltip="Reports"
          include-back
          include-help
          :title="reportName"
        />
        <v-card-text>
          <p :class="`${DEFAULT_TEXT}`">{{ report.description }}</p>
        </v-card-text>

        <v-form
          v-if="isParameterReport"
          class="pt-0 mb-4"
        >
          <v-container class="pt-0">
            <v-row v-for="(parameter, i) in reportParameters" :key="parameter.name">
              <v-col cols="12">
                <v-text-field
                  v-model="parameterValues[i]"
                  :hint="`A comma-delimited list of ${parameter.name}s.`"
                  :label="parameter.name"
                  :error-messages="errorMessages[i]"
                  clearable
                  counter="1000"
                  dense
                  persistent-hint
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-card-actions v-if="!isLoading">
          <v-btn
            @click="generateReport()"
            :disabled="!isValidGenerateRequest"
            color="primary"
            :loading="isReportLoading"
          >
            Generate Report
          </v-btn>
        </v-card-actions>

        <v-data-table
          v-if="isReportLoaded && !isDownloadOnly"
          id="report-table"
          :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
          :headers="reportHeaders"
          :items="resultSet"
          :items-per-page="50"
          :search="search"
          height="100%"
          class="mx-4"
          dense
          fixed-header
          loading-text="Loading, please wait..."
          no-data-text="Table contains no data."
          no-results-text="No results found."
        >
          <template v-slot:top>
            <v-row dense>
              <v-col class="offset-sm-2 col-sm-8 offset-md-3 col-md-6 mb-4" cols="12">
                <v-text-field
                  v-model="search"
                  :prepend-icon="SEARCH_ICON"
                  label="Search"
                  single-line
                  clearable
                  hide-details
                />
              </v-col>
            </v-row>
          </template>
        </v-data-table>

      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { now, transformError } from '../services/utility.service'
import { ICONS, TEXT_COLOR, REPORT_TYPES, ROUTES } from '../constants'
import ReportParameters from '../models/dto/ReportParameters'

import {
  TYPE as CLIENT_TYPE,
  VALIDATION_RULES as CLIENT_VALIDATION_RULES
} from '../models/dto/Client'

import {
  TYPE as AGENT_TYPE,
  VALIDATION_RULES as AGENT_VALIDATION_RULES
} from '../models/dto/Agent'

import {
  TYPE as MORTGAGE_DEAL_TYPE,
  VALIDATION_RULES as MORTGAGE_DEAL_VALIDATION_RULES
} from '../models/dto/MortgageDeal'


export default {
  components: {
    CardToolbar: () => import('../components/layout/toolbars/CardToolbar.vue'),
    HelpText: () => import('../components/layout/HelpText.vue'),
  },
  data: () => ({
    isLoading: false,

    isReportLoading: false,
    isReportLoaded: false,
    reportHeaders: [],
    resultSet: [],
    search: null,
    
    reportId: -1,
    report: {},           // Details about this report.
    reportParameters: [], // Parameters used by this report.
    parameterValues: [],  // Parameter values used in this report.

    errorMessages: [],
    rules: [], // Rules to apply per token for each parameter.
  }),
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      reportList: 'report/getReportList',
      reportParameterList: 'report/getReportParameterList',
    }),
    /** Metadata required by API calls. */
    apiCallMetadata() {
      return { operator_id: this.user.id, dlc: now(), }
    },
    reportName() {
      return this.report == {} ? 'Loading Report' : this.report.name
    },
    isParameterReport() {
      return this.report.report_type === REPORT_TYPES.PARAMETER
    },
    isDownloadOnly() {
      return this.report.download_only
    },
    isValidGenerateRequest() {
      if (this.isParameterReport) {
        return this.hasParameterData && this.hasValidData
      }
      return true
    },
    hasParameterData() {
      return this.reportParameters.length > 0 && this.parameterValues.length > 0 && this.parameterValues[0].length > 0
    },
    hasValidData() {
      switch (this.report.report_type) {
        case REPORT_TYPES.DEFAULT:
          return true;
        case REPORT_TYPES.PARAMETER:
          let hasValidValues = true;

          let values = ''
          let token = ''
          let testResult = false
          this.errorMessages = []

          // For each report parameter's values...
          for (let i = 0; i < this.parameterValues.length; i++) {
            values = this.parameterValues[i].split(",")
            // For each individual token of the value...
            for (let j = 0; j < values.length; j++) {
              token = values[j].trim()
              if (token.length === 0) {
                continue
              }
              for (let k = 0; k < this.rules[i].length; k++) {
                testResult = this.rules[i][k](token)
                if (testResult !== true) {
                  if (this.errorMessages.length === 0) {
                    this.errorMessages.push([])
                  }
                  this.errorMessages[i].push("\"" + token + "\" is invalid. " + testResult)
                  testResult = false
                }
                hasValidValues &= testResult
              }
            }
          }
          
          return hasValidValues
      }

      return false
    }
  },
  methods: {
    ...mapActions({
      fetchReport: 'report/fetchReport',
      fetchReportsList: 'report/fetchReportsList',
      fetchReportTypesList: 'report/fetchReportTypesList',
      fetchReportParameterList: 'report/fetchReportParameterList',
      errorMessage: 'message/errorMessage',
      successMessage: 'message/successMessage',
    }),
    generateReport() {
      if (this.isReportLoading) {
        return
      }
      this.isReportLoading = true

      // Extract the column names from the parameters.
      let columns = this.reportParameters.map(parameter => parameter.name)
      // Trim each element in the delimited list of values.
      let values = this.parameterValues.map(values => values.split(",").map(value => value.trim()).join(","))
      // By default, we treat all reports as using a comma delimiter. This may change in the future.
      let reportParameters = new ReportParameters(columns, values, ",")

      this.fetchReport({
        reportId: this.reportId,
        reportParameters: reportParameters
      })
      .then(response => {
        const blob = new Blob([response], { type: 'text/csv' })
        const filename = this.report.name.toLowerCase().replaceAll(/\(|\)/g, "").replaceAll(/ |\\|\/|_|-/g, "_")
        const url = this.csvFromServer = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${filename}.csv`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      })
      .catch(error => this.errorMessage(`Failed to load report. ${transformError(error)}`))
      .finally(() => {
        this.isReportLoaded = true
        this.isReportLoading = false
      })
    },
    initializeReportPage() {
      this.loading = true

      // Get the report object from the store.
      for (let i = 0; i < this.reportList.length; i++) {
        if (Number(this.reportList[i].id) === Number(this.reportId)) {
          this.report = this.reportList[i]
          break
        }
      }

      // If it uses parameters, determine them.
      if (this.isParameterReport) {
        let parameters = this.report.parameters.split(',')
        for (let i = 0; i < parameters.length; i++) {
          this.reportParameters.push({
            name: this.reportParameterList[parameters[i] - 1].parameter,
            type: this.reportParameterList[parameters[i] - 1].type,
          })
        }
      }

      document.title = `Report - ${this.report.name}`
    },
    getValidationRules() {
      let parameterName = null;
      let parameterType = null;
      let validationRules = null;

      // Determine which type the parameter belongs to and fetch its validation rules.
      for (let i = 0; i < this.reportParameters.length; i++) {
        parameterName = this.reportParameters[i].name
        parameterType = this.reportParameters[i].type.toUpperCase()

        if (Object.hasOwn(AGENT_TYPE, parameterType)) {
          validationRules = AGENT_VALIDATION_RULES[AGENT_TYPE[parameterType]][parameterName]
        }
        else if (Object.hasOwn(MORTGAGE_DEAL_TYPE, parameterType)) {
          validationRules = MORTGAGE_DEAL_VALIDATION_RULES[MORTGAGE_DEAL_TYPE[parameterType]][parameterName]
        }
        else if (Object.hasOwn(CLIENT_TYPE, parameterType)) {
          validationRules = CLIENT_VALIDATION_RULES[CLIENT_TYPE[parameterType]][parameterName]
        }

        this.rules.push(validationRules.rules)
      }
    },
    initTemplateConstants () {
      this.REPORTS_ICON = ICONS.REPORTS
      this.SEARCH_ICON = ICONS.SEARCH
      this.DEFAULT_TEXT = TEXT_COLOR.DEFAULT
      this.REPORTS_ROUTE = ROUTES.REPORTS
    },
  },
  mounted() {
    this.isLoading = true;
    this.fetchReportsList()
      .catch(error => this.errorMessage(`Failed to load report list. ${transformError(error)}`))
      .then(() => this.fetchReportTypesList())
      .catch(error => this.errorMessage(`Failed to load report-type list. ${transformError(error)}`))
      .then(() => this.fetchReportParameterList())
      .catch(error => this.errorMessage(`Failed to load report-parameter list. ${transformError(error)}`))
      .then(() => {
        this.initializeReportPage()
        this.getValidationRules()
        this.isLoading = false;
      })
  },
  created () {
    this.initTemplateConstants()
    this.reportId = this.$route.params.id
  }
}
</script>

<style scoped>
button {
  outline: none;
}
#reports-table {
  cursor: pointer;
}
</style>